<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.DoorProvider.Delete.Title") }}
        </h1>
        <div class="box">
            <h5 class="subtitle is-5">{{ $t("Interface.DoorProvider.Delete.Text") }}</h5>
            <article class="message is-danger">
                <div class="message-body">
                    <h6 class="title is-6">{{ $t("Interface.DoorProvider.Provider") }}</h6>
                    <p>{{ Form.DoorProvider }}</p>
                    <h6 class="title is-6">{{ $t("Interface.DoorProvider.PublicKey") }}</h6>
                    <p>{{ Form.PublicKey }}</p>
                </div>
            </article>
            <b-button
                type="submit"
                class="button is-theme is-danger"
                :loading="IsLoading"
                @click.prevent="submit"
            >
                {{ $t("Interface.Button.Delete") }}
            </b-button>
        </div>
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import {
    DOORPROVIDER_LIST_REQUEST,
    DOORPROVIDER_DELETE_REQUEST
} from "@/store/actions/doorprovider"
import { SYSTEM_STATUS_REQUEST } from "@/store/actions/system"

export default {
    name: "DoorProviderDelete",
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                DoorProvider: null,
                PublicKey: null
            }
        }
    },
    computed: {
        DoorProvider() {
            return this.$store.getters.GET_DOORPROVIDER_BY_ID(parseInt(this.$route.params.id, 10))
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            if (!this.$store.getters.IS_DOORPROVIDERS_LOADED) {
                this.switchLoading()
                this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                    .then(() => this.getData())
                    .finally(() => this.switchLoading())
            }
            else this.getData()
        }
        else this.$router.push({ name: "DoorProvider-List" })
    },
    methods: {
        getData() {
            this.Form.DoorProvider = this.DoorProvider.doorProvider
            this.Form.PublicKey = this.DoorProvider.publicKey
        },
        submit() {
            this.switchLoading()
            this.$store.dispatch(DOORPROVIDER_DELETE_REQUEST, this.$route.params.id)
                .then(() => {
                    this.$store.dispatch(DOORPROVIDER_LIST_REQUEST)
                        .then(() => {
                            this.$store.dispatch(SYSTEM_STATUS_REQUEST)
                                .then(() => this.$router.push({ name: "DoorProvider-List" }))
                        })
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.DoorProvider.Delete.Title")
        }
    }
}
</script>

<style scoped>
h6.title {
    margin-bottom: 0;
}
.message {
    box-shadow: none;
}
.message-body {
    border-radius: 0;
}
</style>
